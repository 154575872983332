
























import { Component, Vue, Prop } from 'vue-property-decorator';
import Masthead from './Common/Masthead.vue';

@Component({
  components: {
    Masthead,
  },
})
export default class SiteMasthead extends Vue {
  @Prop()
  private readonly mini!: boolean;

  @Prop()
  private readonly title!: string;

  @Prop()
  private readonly subtitle!: string;

  @Prop({ default: 'var(--brand-primary)' })
  private readonly siteMastheadColour!: string;

  @Prop()
  private readonly image!: string;

  @Prop({ default: true })
  private readonly darken!: boolean;

  @Prop({ default: false })
  private readonly extraDark!: boolean;

  @Prop()
  private readonly height!: string;
}
